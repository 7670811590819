import * as React from 'react';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from '@inwink/loader';
import { AppTextLabel, withI18nHelper } from '@inwink/i18n';
import type { Entities } from '@inwink/entities/entities';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import type { States } from '@@services/services';
import { CalendarSessionsGenerator } from '@@event/components/calendargenerator.session';
import { wrapReduxStore, IInwinkStore } from '@@store/index';
import type { ILiveSessionStatus } from '../../../api/livesessionstatus';
import { sessionActions, getSessionSurvey, checkIfModerator } from '../data.sessions';

export interface ISessionActionsProps extends ItemTemplateProps {
    sessionActions?: typeof sessionActions;
    i18n?: States.i18nState;
    event?: States.IEventState;
    i18nHelper?: Entities.i18nHelper;
    store?: IInwinkStore;
}

interface ISessionActionsComponentState {
    isLoadingRegister: boolean;
    sessionisfull: boolean;
    userIsSpeaker: boolean;
    userIsModerator: boolean;
    userFavorite?: Entities.ISessionPerson;
    timeslotisoccupied: boolean;
    userSessionIds: string[];
    liveSessionStatus: ILiveSessionStatus;
}

@withI18nHelper()
class SessionActionsComponent extends React.Component<ISessionActionsProps, ISessionActionsComponentState> {
    constructor(props: ISessionActionsProps) {
        super(props);
        this.evaluate = this.evaluate.bind(this);
        this.register = this.register.bind(this);
        this.unregister = this.unregister.bind(this);
        const userstate = this.props.datacontext.userstate as States.IAppUserState;
        const userFavorite = props.data && userstate.currentUser?.data?.registeredSessions?.data
            .filter((rs) => rs.status === "Active" && rs.sessionId === this.props.data.id)[0];

        this.state = {
            isLoadingRegister: false,
            sessionisfull: false,
            userIsSpeaker: props.data && userstate.currentUser?.detail
                ? this.props.data.speakers.some((s) => s.id === userstate.currentUser.detail.id) : false,
            userFavorite: userFavorite,
            userSessionIds: props.data && userstate.currentUser?.data?.registeredSessions
                ? userstate.currentUser.data.registeredSessions.data
                    .filter((rs) => rs.status === "Active").map((rs) => rs.sessionId)
                : [],
            timeslotisoccupied: false,
            liveSessionStatus: null,
            userIsModerator: checkIfModerator(props.user?.data, props.data, props.user?.detail?.id)
        };
    }

    componentDidUpdate(prevProps: ISessionActionsProps) {
        if (this.props.data !== prevProps.data || this.props.user !== prevProps.user) {
            const userstate = prevProps.datacontext.userstate as States.IAppUserState;
            this.setState({
                userIsModerator: checkIfModerator(this.props.user?.data, this.props.data, this.props.user?.detail?.id),
                userIsSpeaker: this.props.data && userstate?.currentUser?.detail
                    ? this.props.data.speakers.some((s) => s.id === userstate.currentUser.detail.id) : false,
                userFavorite: this.props.data && userstate?.currentUser?.data?.registeredSessions?.data
                    .filter((rs) => rs.status === "Active" && rs.sessionId === prevProps.data.id)[0],
                userSessionIds: this.props.data && userstate?.currentUser?.data?.registeredSessions
                    ? userstate.currentUser.data.registeredSessions.data
                        .filter((rs) => rs.status === "Active").map((rs) => rs.sessionId) : []
            });
        }
    }

    evaluate = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        this.props.sessionActions.evaluate(arg.target as HTMLElement, this.props.data, this.props.i18nHelper);
    };

    register(arg: React.MouseEvent<any>) {
        arg.preventDefault();
        arg.stopPropagation();
        const elt = arg.target as HTMLElement;
        const i18n = this.props.i18nHelper;
        this.setState({ isLoadingRegister: true }, () => {
            const action = this.props.sessionActions.register(i18n, elt, this.props.data) as any;
            action.then(() => {
                this.setState({ isLoadingRegister: false });
            }, (err) => {
                this.setState({
                    isLoadingRegister: false,
                    sessionisfull: err.code === 101,
                    timeslotisoccupied: err.code === 102
                });
            });
        });
    }

    unregister(arg: React.MouseEvent<any>) {
        arg.preventDefault();
        arg.stopPropagation();
        const elt = arg.target as HTMLElement;
        this.setState({ isLoadingRegister: true }, () => {
            const action = this.props.sessionActions.unregister(elt, this.props.data) as any;
            action.then(() => {
                this.setState({ isLoadingRegister: false });
            }, () => {
                this.setState({ isLoadingRegister: false });
            });
        });
    }

    addtoagendabutton() {
        let agendaComponent;
        if (this.props.template?.actions?.addtoagenda && this.props.data) {
            const label = this.props.template.actions.addtoagenda.label;
            const eventstate = this.props.datacontext.eventstate as States.IEventState;
            agendaComponent = <CalendarSessionsGenerator
                event={eventstate}
                label={label}
                i18n={this.props.i18n}
                items={this.props.data}
                allSessions={false}
            />;
        } else {
            agendaComponent = null;
        }
        return agendaComponent;
    }

    favbutton() {
        const i18n = this.props.i18nHelper;
        const eventstate = this.props.datacontext.eventstate as States.IEventState;
        const session = eventstate.data.sessions.data.find((s) => s.id === this.props.data.id);
        const label = this.props.template?.actions?.favorites ? this.props.template.actions.favorites.states : null;
        const eventconf: Entities.IEventDetailConfiguration = this.props.datacontext.event?.configuration;
        const disableLogin = eventconf && eventconf.companion && eventconf.companion.disableUserCanLogin;
        const allowFav = eventconf && eventconf.companion && (eventconf.companion.useFavorites || !disableLogin);
        const eventEnded = new Date(this.props.datacontext.event.endDate) < new Date();
        const hasParticipate = this.state.userFavorite && this.state.userFavorite.participationDate;
        if (!allowFav || (global as any).disableAuthenticatedFeatures || eventEnded || hasParticipate) {
            return null;
        }

        if (session?.isForAllAttendees) {
            let txt = <AppTextLabel i18n="session.forallattendees" />;
            if (label?.forallattendees && label.forallattendees[i18n.i18n.currentLanguageCode]) {
                txt = <AppTextLabel i18n={label.remove[i18n.i18n.currentLanguageCode]} />;
            }
            return <button
                type="button"
                key="addremovefav"
                className="checked not-allowed forallattendees"
                disabled
            >
                {txt}
            </button>;
        }

        if (this.state.isLoadingRegister) {
            return <button
                type="button"
                key="addremovefav"
                className="bloc-lightborder lightbtn"
            >
                <div className="asyncloader"><Loader /></div>
            </button>;
        }

        if (this.state.userFavorite && new Date(this.props.event.detail.endDate) > new Date()) {
            let txt = <AppTextLabel i18n="session.removefromagenda" />;
            if (label?.remove && label.remove[i18n.i18n.currentLanguageCode]) {
                txt = <AppTextLabel i18n={label.remove[i18n.i18n.currentLanguageCode]} />;
            }
            return <button
                type="button"
                key="addremovefav"
                className="removefromagenda bloc-lightborder lightbtn checked"
                onClick={this.unregister}
            >
                <i className="inwink-bookmark" />
                {txt}
            </button>;
        }

        if (this.props.data) {
            if (this.state.sessionisfull || (session.quota != null && session.infos && session.infos.placesAvailable < 1)) {
                let txt = <AppTextLabel i18n="session.full" />;
                if (label?.full && label.full[i18n.i18n.currentLanguageCode]) {
                    txt = <AppTextLabel i18n={label.full[i18n.i18n.currentLanguageCode]} />;
                }
                return <button
                    type="button"
                    key="addremovefav"
                    className="full not-allowed"
                    disabled
                >
                    {txt}
                </button>;
            }

            if ((this.state.userSessionIds
                && this.state.userSessionIds.length > 0
                && session.quota != null
                && session.timeslots
                && session.timeslots.length > 0) || this.state.timeslotisoccupied) {
                let isOk = true;
                // Check no other sessions on the same timeslot dates range
                const sessions = eventstate.data.sessions.data.filter((s) => s.timeslots
                    && s.timeslots.length > 0
                    && s.quota != null
                    && this.state.userSessionIds.filter((id) => s.id === id).length > 0);
                if (sessions && sessions.length > 0) {
                    const currentSessionStartDate = moment(session.timeslots[0].startDate);
                    const currentSessionEndDate = moment(session.timeslots[0].endDate);
                    for (let idx = 0; idx < sessions.length; idx += 1) {
                        const s = sessions[idx];
                        const sStartDate = moment(s.timeslots[0].startDate);
                        const sEndDate = moment(s.timeslots[0].endDate);

                        if (currentSessionStartDate < sEndDate && sStartDate < currentSessionEndDate) {
                            isOk = false;
                            break;
                        }
                    }
                }

                if (!isOk || this.state.timeslotisoccupied) {
                    let txt = <AppTextLabel i18n="session.occupied" />;
                    if (label?.full && label.full[i18n.i18n.currentLanguageCode]) {
                        txt = <AppTextLabel i18n={label.full[i18n.i18n.currentLanguageCode]} />;
                    }
                    return <button
                        type="button"
                        key="addremovefav"
                        className="occupied"
                    >
                        {txt}
                    </button>;
                }
            }

            let txt = <AppTextLabel i18n="session.addtoagenda" />;
            if (label?.add && label.add[i18n.i18n.currentLanguageCode]) {
                txt = <AppTextLabel i18n={label.add[i18n.i18n.currentLanguageCode]} />;
            }

            if (new Date(this.props.event.detail.endDate) > new Date()) {
                return <button
                    type="button"
                    key="addremovefav"
                    className="addtoagenda lightbtn bloc-lightborder"
                    onClick={this.register}
                >
                    <i className="inwink-bookmark" />
                    {txt}
                </button>;
            }
        }

        return null;
    }

    evaluateSessionBtn() {
        const session = this.props.data as Entities.ISession;

        if (session) {
            const eventstate = this.props.datacontext.eventstate as States.IEventState;
            const survey = getSessionSurvey(eventstate.data, session, this.props.i18n);

            if (survey) {
                return <button
                    type="button"
                    key="evaluate"
                    className="lightbtn"
                    onClick={this.evaluate}
                >
                    <i className="inwink-star" />
                    <AppTextLabel i18n="session.evaluate" />
                </button>;
            }
        }
    }

    componentDidMount() {
        if (this.props.data) {
            this.checkLiveStatus();
            const session = this.props.data as Entities.ISession;
            if (session && session.liveSessionId) {
                if (typeof document !== "undefined") {
                    document.body.addEventListener("inwink.livesession.start." + session.liveSessionId, this.checkLiveStatus);
                }
            }
        }
    }

    componentWillUnmount() {
        const session = this.props.data as Entities.ISession;
        if (session && session.liveSessionId) {
            if (typeof document !== "undefined") {
                document.body.removeEventListener("inwink.livesession.start." + session.liveSessionId, this.checkLiveStatus);
            }
        }
    }

    checkLiveStatus = () => {
        const session = this.props.data as Entities.ISession;
        if (session && session.liveSessionId) {
            import('../../../api/livesessionstatus').then((mod) => {
                mod.getLiveSessionStatus(this.props.event.requestManagers, session.liveSessionId).then((livestatus) => {
                    this.setState({ liveSessionStatus: livestatus });
                });
            });
        }
    };

    showLiveBtn(session: Entities.ISession) {
        if (session && session.liveSessionId && this.state.liveSessionStatus && this.state.liveSessionStatus.isActive) {
            return <Link to={this.props.urlservice.pageUrl("livesession/" + session.liveSessionId)} role="button">
                <AppTextLabel i18n="session.joinlive" />
            </Link>;
        }
    }

    liveAdminBtn(session: Entities.ISession) {
        return <Link to={this.props.urlservice.pageUrl("livesessionboard/" + session.liveSessionId)} role="button">
            <AppTextLabel i18n="session.managelive" />
        </Link>;
    }

    liveSecondScreen(session: Entities.ISession) {
        return <Link
            to={this.props.urlservice.pageUrl("livesessionwall/" + session.liveSessionId + "?_chromeless=1")}
            role="button"
        >
            <AppTextLabel i18n="session.openlivewall" />
        </Link>;
    }

    render() {
        const session = this.props.data as Entities.ISession;
        let favbtn; let evaluatebtn; let showlivebtn; let liveadminbtn; let liveSecondScreen; let
            generateCalendar;
        const eventconf = this.props.event
            && this.props.event.detail
            && this.props.event.detail.configuration;
        const canManage = (eventconf && eventconf.speakers && eventconf.speakers.canManageLiveSession)
            || (eventconf.companion.liveSessions && eventconf.companion.liveSessions.allowSpeakers);

        if (session) {
            favbtn = this.favbutton();
            evaluatebtn = this.evaluateSessionBtn();
            generateCalendar = this.addtoagendabutton();
            if (session.liveSessionId && this.state.liveSessionStatus) {
                if (this.state.userIsSpeaker || this.state.userIsModerator) {
                    showlivebtn = this.showLiveBtn(session);
                    if (canManage) {
                        liveadminbtn = this.liveAdminBtn(session);
                        liveSecondScreen = this.liveSecondScreen(session);
                    }
                } else if (this.state.liveSessionStatus.status === "Published" || InWinkPreview) {
                    const userstate = this.props.datacontext.userstate as States.IAppUserState;
                    const canAccess = (this.state.liveSessionStatus.allowAnonymous || userstate.currentUser)
                            && (!this.state.liveSessionStatus.onlyForRegistered || this.state.userFavorite);

                    if (canAccess) {
                        showlivebtn = this.showLiveBtn(session);
                    }
                }
            }
        }

        return <section className="entity-actions">
            {favbtn}
            {generateCalendar}
            {evaluatebtn}
            {showlivebtn}
            {liveadminbtn}
            {liveSecondScreen}
        </section>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        event: state.event
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sessionActions: bindActionCreators(sessionActions, dispatch)
    };
}

export const SessionActions: new (any)
=> React.Component<ISessionActionsProps, any> = connect(
    mapStateToProps,
    mapDispatchToProps
)(wrapReduxStore(SessionActionsComponent) as any) as any;
