import { registerBloc } from '../../../components/dynamicpage/blocscatalog';
import { registerSessionTemplateParts } from './sessions/registerItemTemplateParts';
import { BlocTeaserSessions, blocTeaserSessionsData } from "./sessions/blocs/bloc.teasersessions";
import { BlocTeaserSessionsByDays, blocTeaserSessionsByDaysData } from './sessions/blocs/bloc.teasersessionsbydays';

registerSessionTemplateParts();

registerBloc("teasersessions", {
    component: BlocTeaserSessions,
    datainit: blocTeaserSessionsData
});

registerBloc("teasersessionsbydays", {
    component: BlocTeaserSessionsByDays,
    datainit: blocTeaserSessionsByDaysData
});
