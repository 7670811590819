import * as React from 'react';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from '@inwink/loader';
import type { Entities } from '@inwink/entities/entities';
import * as moment from 'moment';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import { AppTextLabel, DynLabel } from '@@services/i18nservice';
import type { States } from '@@services/services';
import { CalendarSessionsGenerator } from '@@event/components/calendargenerator.session';
import { AppLink } from '@@components/applink';
import { wrapReduxStore, IInwinkStore } from '@@store/index';
import { getTimeZoneDate } from '@@components/displaytimezonedate';
import { sessionActions, getSessionSurvey } from '../data.sessions';

import './sessionitemaction.less';

interface ISessionItemActionProps extends ItemTemplateProps {
    sessionActions?: typeof sessionActions;
    i18n?: States.i18nState;
    event?: States.IEventState;
    i18nHelper?: Entities.i18nHelper;
    store?: IInwinkStore;
}

@withI18nHelper()
class SessionItemActionComponent extends React.Component<ISessionItemActionProps, any> {
    constructor(props: ItemTemplateProps) {
        super(props);
        this.state = {};
    }

    register = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        const elt = arg.target as HTMLElement;
        const i18n = this.props.i18nHelper;
        this.setState({ isLoadingRegister: true }, () => {
            const action = this.props.sessionActions.register(i18n, elt, this.props.data) as any;
            action.then(() => {
                this.setState({ isLoadingRegister: false });
            }, (err) => {
                this.setState({ isLoadingRegister: false });
                this.showErrorMessage(err.code);
            });
        });
    };

    exportLead = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        const sessionId = this.props.data.id;
        const currentEa = this.props.datacontext.customcontext.exhibitorAccount;
        const eventDefaultLanguage = this.props.datacontext.event.configuration.global.defaultLanguage;
        const title = this.props.data.title[eventDefaultLanguage];
        let shortTitle = title;

        if (title.length > 56) {
            shortTitle = title.substring(0, 56) + "...";
        }

        const i18n = this.props.i18nHelper;

        return import("@@partnerworkspace/api/exhibitorsessions").then((mod) => {
            return mod.partnerExhibitorSessionLeadsExport(this.props.event.requestManagers,
                currentEa.exhibitorId,
                sessionId,
                i18n.translate("partnerwrk.scans.sessions.downloadfilename") + ' - ' + shortTitle,
                i18n.i18n.currentLanguageCode,
                {
                    "Session.Title": i18n.translate("partnerwrk.sessions.export.column.session"),
                    RegistrationDate: i18n.translate("partnerwrk.sessions.export.column.registrationdate"),
                    ParticipationDate: i18n.translate("partnerwrk.sessions.export.column.participationdate"),
                    "Person.Firstname": i18n.translate("partnerwrk.sessions.export.column.personfirstname"),
                    "Person.Lastname": i18n.translate("partnerwrk.sessions.export.column.personlastname"),
                    "Person.Mail": i18n.translate("partnerwrk.sessions.export.column.personemail"),
                    "Person.Company": i18n.translate("partnerwrk.sessions.export.column.personcompany"),
                    "Person.JobTitle": i18n.translate("partnerwrk.sessions.export.column.personjobtitle")
                });
        });
    };

    unregister = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        const elt = arg.target as HTMLElement;
        this.setState({ isLoadingRegister: true }, () => {
            const action = this.props.sessionActions.unregister(elt, this.props.data) as any;
            action.then(() => {
                this.setState({ isLoadingRegister: false });
            }, (err) => {
                this.setState({ isLoadingRegister: false });
                this.showErrorMessage(err.code);
            });
        });
    };

    exportButton() {
        return <button
            type="button"
            key="addremovetofav"
            className="action lightbtn favaction clickable bloc-lightborder inwink-file"
            onClick={this.exportLead}
        />;
    }

    sessionToCalendar() {
        const eventstate = this.props.datacontext.eventstate as States.IEventState;
        return <CalendarSessionsGenerator event={eventstate} itemAction items={this.props.data} i18n={this.props.i18n} />;
    }

    favbutton() {
        const eventEnded = new Date(this.props.datacontext.event.endDate) < new Date();
        let sessionEndDate;
        let sessionStartDate;
        let sessionEnded;
        const currentDate = moment();
        if (this.props.data?.timeslots?.length) {
            sessionEndDate = this.props.data.timeslots[0]?.endDate;
            sessionStartDate = this.props.data.timeslots[0]?.startDate;
        }

        if (sessionEndDate) {
            sessionEnded = getTimeZoneDate(sessionEndDate, this.props.event?.detail, this.props.i18n).isBefore(currentDate);
        } else if (!sessionEndDate || !sessionStartDate) {
            sessionEnded = false;
        }
        if (eventEnded || (global as any).disableAuthenticatedFeatures || sessionEnded) {
            return undefined;
        }
        const addTofavoriteActionisSecondary = this.props.datacontext
            && (this.props.datacontext as any)?.templateOptions?.addToFavoriteActionSecondary;
        const userstate = this.props.datacontext.userstate as States.IAppUserState;
        const conf = (this.props.template && this.props.template.properties && this.props.template.properties.favorite) || {};
        const classes = ["action", "favaction", "iconbtn"];
        let content = null;
        let onClick = null;
        const { onRegistrationSessionClicked, isSelected } = this.props.datacontext.customcontext || {};
        let isDisabled = false;
        let label = null;
        const i18n = this.props.i18nHelper;
        let buttonTitle;

        let addTofavoriteActionDisplay = this.props.datacontext
            && (this.props.datacontext as any)?.templateOptions?.addToFavoriteActionDisplay;
        let displayAsText;
        let displayAsIcon;
        let noDisplay;
        if (addTofavoriteActionDisplay && addTofavoriteActionDisplay.length) {
            if (typeof addTofavoriteActionDisplay === 'string') {
                addTofavoriteActionDisplay = [addTofavoriteActionDisplay];
            }
            addTofavoriteActionDisplay.map((a) => {
                if (a === "icon") {
                    displayAsIcon = true;
                } else if (a === "text") {
                    displayAsText = true;
                }
                classes.push(a);
                return null;
            });
        } else {
            noDisplay = true;
        }

        if (this.state.isLoadingRegister) {
            content = <div className="asyncloader"><Loader message=" " /></div>;
            classes.push('loading');
        } else if (this.props.data.isForAllAttendees) {
            isDisabled = true;
            classes.push("checked");
            if (conf.labels && conf.labels.forallattendees) {
                label = <DynLabel className="actiontext" i18n={conf.labels.forallattendees} />;
                buttonTitle = i18n.translateBag(conf.labels.forallattendees);
            } else {
                label = <AppTextLabel className="actiontext" i18n="session.forallattendees" />;
                buttonTitle = i18n.translate("session.forallattendees");
            }
        } else {
            if (userstate && userstate.currentUser && userstate.currentUser.data) {
                const userfavorite = userstate.currentUser.data.registeredSessions.data
                    .filter((rs) => rs.status === "Active" && this.props.data && rs.sessionId === this.props.data.id)[0];
                if (userfavorite && (userfavorite.isRegisted || userfavorite.registrationDate)) {
                    if (userfavorite.participationDate) {
                        return undefined;
                    }
                    isDisabled = false;
                    classes.push("checked");
                    onClick = this.unregister;
                    if (conf.labels && conf.labels.remove) {
                        label = <DynLabel className="actiontext" i18n={conf.labels.remove} />;
                        buttonTitle = i18n.translateBag(conf.labels.remove);
                    } else {
                        label = <AppTextLabel className="actiontext" i18n="session.removefromagenda" />;
                        buttonTitle = i18n.translate("session.removefromagenda");
                    }
                }
            }

            if (!label && this.props.data?.quota && this.props.data?.infos?.placesAvailable < 1) {
                isDisabled = true;
                if (conf.labels && conf.labels.full) {
                    label = <DynLabel className="actiontext" i18n={conf.labels.full} />;
                    buttonTitle = i18n.translateBag(conf.labels.full);
                } else {
                    label = <AppTextLabel className="actiontext" i18n="session.full" />;
                    buttonTitle = i18n.translate("session.full");
                }
            }

            if (!onClick && !isDisabled) {
                onClick = onRegistrationSessionClicked || this.register;
                if (onRegistrationSessionClicked && isSelected) {
                    classes.push("checked");
                    if (conf.labels && conf.labels.remove) {
                        label = <DynLabel className="actiontext" i18n={conf.labels.remove} />;
                        buttonTitle = i18n.translateBag(conf.labels.remove);
                    } else {
                        label = <AppTextLabel className="actiontext" i18n="session.removefromagenda" />;
                        buttonTitle = i18n.translate("session.removefromagenda");
                    }
                } else {
                    if (conf.labels && conf.labels.add) {
                        label = <DynLabel className="actiontext" i18n={conf.labels.add} />;
                        buttonTitle = i18n.translateBag(conf.labels.add);
                    } else {
                        label = <AppTextLabel i18n="session.addtoagenda" />;
                        buttonTitle = i18n.translate("session.addtoagenda");
                    }
                }
            }
        }

        const inwinkIcon = conf.inwinkIcon || "inwink-bookmark";
        if (conf.showLabel || displayAsText) {
            classes.push("with-label");
            const iconbtnIX = classes.indexOf("iconbtn");
            if (iconbtnIX > -1) {
                classes.splice(iconbtnIX, 1);
            }
            if (displayAsIcon && !conf.hideIcon && !this.state.isLoadingRegister) {
                content = <><i className={inwinkIcon} /> {label}</>;
            } else {
                content = <>{label}</>;
            }
        } else if (!this.state.isLoadingRegister && (displayAsIcon || noDisplay)) {
            classes.push(inwinkIcon);
        }

        if (isDisabled) {
            classes.push('not-allowed');
            onClick = null;
        }
        if (onClick != null) {
            classes.push("clickable");
        }

        if (addTofavoriteActionisSecondary) {
            classes.push("lightbtn");
        }

        return <button
            type="button"
            key="addremovetofav"
            disabled={isDisabled}
            className={classes.join(" ")}
            onClick={onClick}
            title={buttonTitle}
        >{content}</button>;
    }

    showErrorMessage = (code: number) => {
        if (code === 101) {
            this.setState({ errorFullSession: true }, () => {
                setTimeout(() => {
                    this.setState({ errorFullSession: false });
                }, 3000);
            });
        } else if (code === 102) {
            this.setState({ errorFullTimeslot: true }, () => {
                setTimeout(() => {
                    this.setState({ errorFullTimeslot: false });
                }, 3000);
            });
        } else {
            this.setState({ errorUnknown: true }, () => {
                setTimeout(() => {
                    this.setState({ errorUnknown: false });
                }, 3000);
            });
        }
    };

    getSharingLinks() {
        if (this.props.template?.properties?.itemActionLinks?.length) {
            return this.props.template.properties.itemActionLinks.map((l, index) => {
                const link = l;
                if (!link.args) {
                    link.args = `session/${this.props.data.id}`;
                }
                return <AppLink
                    key={`link${index}`}
                    className={link.className}
                    i18n={this.props.i18n}
                    link={link}
                    onClick={this.cancelPropagation}
                >{link.name}</AppLink>;
            });
        }
    }

    goToSession() {
        if (this.props.urlservice && this.props.data && this.props.data.id) {
            const link = this.props.urlservice.pageUrl('session');
            return <AppLink
                className="inwink-visibility"
                to={link + '/' + this.props.data.id}
                onClick={this.cancelPropagation}
            />;
        }
    }

    cancelPropagation = (arg: React.MouseEvent<any>) => {
        arg.stopPropagation();
    };

    evaluate = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        this.props.sessionActions.evaluate(arg.target as HTMLElement, this.props.data, this.props.i18nHelper);
    };

    evaluateSessionBtn() {
        const session = this.props.data as Entities.ISession;

        if (session) {
            const evaluateSessionActionisSecondary = this.props.datacontext
                && (this.props.datacontext as any)?.templateOptions?.evaluateSessionActionSecondary;
            let evaluateSessionActionDisplay = this.props.datacontext
                && (this.props.datacontext as any)?.templateOptions?.evaluateSessionActionDisplay;
            const eventstate = this.props.datacontext.eventstate as States.IEventState;
            const survey = getSessionSurvey(eventstate.data, session, this.props.i18n);
            if (survey) {
                const i18n = this.props.i18nHelper;
                const conf = (this.props.template?.properties?.evaluate) || {};
                const classes = ["action", "evaluateaction"];
                const inwinkIcon = conf.inwinkIcon || "inwink-star";
                let content;
                let displayAsText;
                let displayAsIcon;
                let noDisplay;
                const label = <AppTextLabel i18n="session.evaluate" />;
                if (evaluateSessionActionDisplay && evaluateSessionActionDisplay.length) {
                    if (typeof evaluateSessionActionDisplay === 'string') {
                        evaluateSessionActionDisplay = [evaluateSessionActionDisplay];
                    }
                    evaluateSessionActionDisplay.map((e) => {
                        if (e === "icon") {
                            displayAsIcon = true;
                        } else if (e === "text") {
                            displayAsText = true;
                        }
                        classes.push(e);
                        return null;
                    });
                } else {
                    noDisplay = true;
                }
                if (evaluateSessionActionisSecondary) {
                    classes.push("lightbtn");
                }
                if (conf.showLabel || displayAsText) {
                    classes.push("with-label");
                    if (!conf.hideIcon && displayAsIcon) {
                        content = <><i className={inwinkIcon} /> {label}</>;
                    } else {
                        content = <>{label}</>;
                    }
                } else if (displayAsIcon || noDisplay) {
                    classes.push(inwinkIcon, "iconbtn");
                }
                return <button
                    type="button"
                    key="evaluate"
                    className={classes.join(" ")}
                    onClick={this.evaluate}
                    title={i18n.translate("session.evaluate")}
                >
                    {content}
                </button>;
            }
        }
    }

    render() {
        let favorites;
        let sessionToCalendar;
        let share;
        let goToSession;
        let evaluateSession;
        const currentTemplate = this.props.template;
        const disableFavLink = currentTemplate?.properties?.disableFavLink;
        const disablePartnerSharing = currentTemplate?.properties?.disablePartnerSharing;
        const disablePartnerGoToSession = currentTemplate?.properties?.disablePartnerGoToSession;
        const disablePartnerExport = currentTemplate?.properties?.disablePartnerExport;
        const disableICS = currentTemplate?.properties?.disableICS;
        const disableEvaluate = currentTemplate?.properties?.disableEvaluate;
        const eventconf: Entities.IEventDetailConfiguration = this.props.datacontext?.event?.configuration;
        const allowFav = eventconf?.companion && (eventconf.companion.useFavorites || !eventconf.companion.disableUserCanLogin);
        const allowExportToCalendar = this.props.page?.data?.sessionssearch?.itemtemplate?.template?.allowexporttocalendar;

        const currentUserIsExhibitorAccount = this.props.datacontext.customcontext?.exhibitorAccount;
        const isPartnerSession = (this.props.page && this.props.page.id) === 'partnersessions' && currentUserIsExhibitorAccount;
        if (isPartnerSession) {
            share = !disablePartnerSharing && this.getSharingLinks();
            goToSession = !disablePartnerGoToSession && this.goToSession();
            if (!disablePartnerExport && eventconf?.exhibitors?.exhibitorsSessionsExportMode !== 2) {
                favorites = this.exportButton();
            }
        } else if (allowFav && !disableFavLink) {
            favorites = this.favbutton();
        }

        if (allowExportToCalendar && !disableICS) {
            sessionToCalendar = this.sessionToCalendar();
        }

        if (!disableEvaluate) {
            evaluateSession = this.evaluateSessionBtn();
        }

        let msg = null;
        if (this.state.errorFullSession) {
            msg = <AppTextLabel component="div" className="session-error" i18n="sessions.action.errorsessionfull" />;
        } else if (this.state.errorFullTimeslot) {
            msg = <AppTextLabel component="div" className="session-error" i18n="sessions.action.errortimeslotfull" />;
        } else if (this.state.errorUnknown) {
            msg = <AppTextLabel component="div" className="session-error" i18n="sessions.action.errorunknown" />;
        }

        return <div className={"sessionitemactions" + (this.props.template.className ? " " + this.props.template.className : "")}>
            {msg}
            {favorites}
            {evaluateSession}
            {sessionToCalendar}
            {share}
            {goToSession}
        </div>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        event: state.event
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sessionActions: bindActionCreators(sessionActions, dispatch)
    };
}

export const SessionItemAction: new (any)
=> React.Component<ISessionItemActionProps, any> = connect(
    mapStateToProps,
    mapDispatchToProps
)(wrapReduxStore(SessionItemActionComponent) as any) as any;
