import * as React from 'react';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { Entities } from '@inwink/entities/entities';
import { templatedString } from '@inwink/utils/methods/templatedString';
import { getBlocTemplate } from '@@services/itemshelpers';
import {
    getComingSessions, getThemeSessions, getSessionsByIds,
    getSessionsContainsSameThemes, getSessionsContainsSameRoom, getRecommendationPredicate, getJourneySessions
} from '../data.sessions';
import type { IDynamicBlocProps } from '@@components/dynamicpage/common';
import { TeaserItems } from '@@components/dynamicpage/teaseritems';
import { SortAndFilterItems } from '@@components/dynamicpage/teaseritems.props';
import type { States } from '@@services/services';
import { withI18nHelper } from '@inwink/i18n';
import { getUrlSlug } from 'helpers';

import './bloc.teasersessions.less';

export interface IBlocTeaserSessionsState {
    sessions: Entities.ISession[];
    itemtemplate: VisualTheme.IItemTemplate;
    loading: boolean;
}

export function blocTeaserSessionsData(page: States.ICurrentPageState, statedata: IBlocTeaserSessionsState,
    blc: VisualTheme.IBlocContentTemplateBase, state: States.IAppState) {
    const blocdata = statedata;
    const bloc = blc;
    const data = state.event.data;
    const itemtemplate = getBlocTemplate(data, bloc);
    let sessions: Entities.ISession[];

    if (bloc.properties.whitelist && bloc.properties.whitelist.length) {
        sessions = getSessionsByIds(state.i18n, state.user, bloc.properties.whitelist.map((s) => { return { id: s }; }),
            data, !bloc.properties.showAllSessions, bloc.properties.showOnlyComingSessionsTest);
    } else if (page.context && page.context.entityKind && page.context.entityKind === "EventTheme" && page.context.entity) {
        sessions = getThemeSessions(state.i18n, state.user, page.context.entity.id, data,
            !bloc.properties.showAllSessions, bloc.properties.showOnlyComingSessionsTest);
    } else if (page.context && page.context.entityKind && page.context.entityKind === "Journey" && page.context.entity) {
        sessions = getJourneySessions(state.i18n, state.user, page.context.entity.id, data,
            !bloc.properties.showAllSessions, bloc.properties.showOnlyComingSessionsTest);
    } else if (page.context && page.context.entityKind && page.context.entityKind === "Speaker" && page.context.entity) {
        sessions = getSessionsByIds(state.i18n, state.user, page.context.entity.sessions, data,
            !bloc.properties.showAllSessions, bloc.properties.showOnlyComingSessionsTest);
    } else if (page.context && page.context.entityKind && page.context.entityKind === "Person" && page.context.entity) {
        sessions = getSessionsByIds(state.i18n, state.user, page.context.entity.sessions, data,
            !bloc.properties.showAllSessions, bloc.properties.showOnlyComingSessionsTest);
    } else if (page.context && page.context.entityKind && page.context.entityKind === "Exhibitor" && page.context.entity) {
        const ids = (data?.sessions?.data || []).filter((s) => {
            if (s.exhibitors?.length && s.exhibitors.map((e) => e.id).indexOf(page.context.entity.id) !== -1) return true;
            return false;
        }).map((s) => {
            return { id: s.id };
        });
        sessions = getSessionsByIds(state.i18n, state.user, ids, data,
            !bloc.properties.showAllSessions, bloc.properties.showOnlyComingSessionsTest);
    } else if (page.context && page.context.entityKind === "Session" && page.context.entity) {
        sessions = getSessionsContainsSameThemes(page.context.entity, data, state.i18n,
            !bloc.properties.showAllSessions, bloc.properties.showOnlyComingSessionsTest);
    } else if (page.context && page.context.entityKind === "Room" && page.context.entity) {
        sessions = getSessionsContainsSameRoom(page.context.entity, data, state.i18n,
            !bloc.properties.showAllSessions, bloc.properties.showOnlyComingSessionsTest);
    } else {
        if (!bloc.properties.showAllSessions) bloc.properties.showAllSessions = false;

        sessions = getComingSessions(state.i18n, state.user, state.event, data?.sessions?.data ?? [],
            bloc.properties.showAllSessions, state.event.detail?.customDate);
    }

    if (bloc.properties.onlyFavorites) {
        sessions = sessions
            .filter((s) => state.user?.currentUser?.data?.registeredSessions?.data?.filter((rs) => 
                rs.sessionId === s.id && rs.status === "Active")[0]);
    }

    if (bloc.properties?.showRecommandations && state?.user?.currentUser) {
        const recommandationsPredicate = getRecommendationPredicate(state.user, null, true);
        const recommendedItems = sessions.filter((s) => recommandationsPredicate(s));
        if (recommendedItems?.length) {
            sessions = recommendedItems;
        }

    }

    blocdata.sessions = SortAndFilterItems(state.user, state.i18n, sessions, bloc.properties, false, page.context.entity);
    blocdata.itemtemplate = itemtemplate;
}

const defaultLayout = {
    default: "col2",
    L: "col2",
    M: "col1",
    S: "col2",
    XXS: "col1",
    XXXS: "col1",
    XXXXS: "col1"
};

interface IBlocTeaserSessionsProps extends IDynamicBlocProps<IBlocTeaserSessionsState> {
    i18nHelper?: Entities.i18nHelper;
}

@withI18nHelper()
export class BlocTeaserSessions extends React.PureComponent<IBlocTeaserSessionsProps, any> {
    constructor(props: IBlocTeaserSessionsProps) {
        super(props);
        const itemtemplate = props.blocState?.itemtemplate || props.template?.properties?.template;
        this.state = {
            itemtemplate,
            entitytemplate: this.props.event?.data?.fieldtemplates
                && this.props.event.data.fieldtemplates.data.find((f) => f.entityName.toLowerCase() === "session"),
            // sessions: this.getSessions(props)
        };
    }

    getSessionUrl = (session: Entities.ISession) => {
        if (this.props.template.properties && this.props.template.properties.targetPage) {
            return this.props.urlservice.pageUrl(templatedString(this.props.template.properties.targetPage,
                { ...this.props.datacontext, entity: session }));
        }

        // if online session and direct link template option = true
        const isOnsite = session.sessionType.some((st) => st === 'Onsite');
        const itemtemplate = (this.props.blocState?.itemtemplate as any);
        const sessionTitle = this.props.i18nHelper?.translateBag(session.title);
        let isDirectLink;
        if (itemtemplate) {
            isDirectLink = itemtemplate.templateOptions?.onlineSessionLink;
        }
        if (!isOnsite && isDirectLink) {
            return this.props.urlservice.pageUrl(`onlinesession/${session.id}/${getUrlSlug(sessionTitle)}`);
        }

        // if onsite session or online session and direct link template option = false
        return this.props.urlservice.pageUrl(`session/${session.id}/${getUrlSlug(sessionTitle)}`);
    };

    componentDidUpdate(prevProps: IDynamicBlocProps<IBlocTeaserSessionsState>) {
        if (this.props.user?.checked && !prevProps.user?.checked) {
            const newdata = {} as any;
            blocTeaserSessionsData(this.props.page, newdata, this.props.template, {
                event: this.props.event,
                user: this.props.user,
                i18n: this.props.i18n
            } as any);
            this.props.update(newdata);
            // const sessions = getComingSessions(this.props.i18n, this.props.user,
            //     this.props.event, this.props.event?.data?.sessions?.data);
            // const displayedSessions = this.getSessions(this.props, sessions);
            // this.setState({ sessions: displayedSessions });
        }
        if (prevProps.blocState !== this.props.blocState) {
            this.setState({ itemtemplate: this.props.blocState.itemtemplate });
        }
    }

    render() {
        let link;

        if (!this.props.blocState || !this.state.itemtemplate) {
            return <div className="empty" />;
        }

        if (this.props.template && this.props.template.properties && !this.props.template.properties.disableNavigation) {
            link = this.getSessionUrl;
        }

        let className;
        if (this.props.blocState?.itemtemplate && (this.props.blocState.itemtemplate as any).customCSS === undefined) {
            className = "defaultsessionitem";
        }

        return <TeaserItems
            {...this.props}
            className={"teasersessionitems " + (className || "")}
            entitySchema={this.state.entitytemplate}
            defaultItemsAlign="left"
            defaultItemsLayout={defaultLayout}
            templateProperties={this.props.template?.properties?.templateProperties}
            allowEmpty={this.props.template?.properties?.allowEmpty}
            emptyMessage={this.props.template?.properties?.emptyMessage}
            minCarouselItems={this.props.template?.properties?.minCarouselItems}
            linkto={link}
            items={this.props.blocState.sessions}
            itemClassName="session-item"
            templateEditable={false}
            itemtemplate={this.state.itemtemplate}
        />;
    }
}
