import * as React from 'react';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import type { Entities } from '@inwink/entities/entities';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import { ItemTemplate } from '@@components/templates/itemtemplate';
import type { States } from '@@services/services';
import { getBlocTemplateFor } from '@@services/itemshelpers';
import { SortAndFilterItems } from '@@components/dynamicpage/teaseritems.props';
import { withItemTemplateProvider, IItemTemplateProvider } from '@@components/templates/itemtemplateprovider';

import './sessionitemspeakers.less';

export interface ISessionItemSpeakersProps extends ItemTemplateProps {
    itemtemplateprovider?: IItemTemplateProvider;
    event?: States.IEventState;
    data: {
        speakers?: {
            id: string
        }[],
        speakersData?: Entities.ISpeaker[]
    };
    theme: any;
    // sort?: { propName: string, order: string };
    // filter?: { propName: string, value: any };
    // maxItems?: number;
    i18n: States.i18nState;
    roomId?: string;
}

@connectwith((state) => { return { event: state.event }; })
@withItemTemplateProvider()
export class SessionItemSpeakers extends React.PureComponent<ISessionItemSpeakersProps, any> {
    constructor(props: ISessionItemSpeakersProps) {
        super(props);

        let speakers;
        if (this.props.data.speakersData?.length > 0) {
            speakers = this.props.data.speakersData.filter((s) => !!s);
        } else if (this.props.data.speakers?.length > 0 && this.props.datacontext && this.props.datacontext.eventstate) {
            const eventstate = this.props.datacontext.eventstate as States.IEventState;
            speakers = this.props.data.speakers.map((s) => {
                return eventstate.data.speakers.data.find((sp) => sp.id === s.id);
            }).filter((s) => !!s);
        }

        if (speakers) {
            const properties = { ...this.props.template && (this.props.template as any).templateProperties };
            if (!properties.sort) {
                properties.sort = { propName: "lastname" };
            }
            speakers = SortAndFilterItems(this.props.user && this.props.user.detail as any,
                this.props.i18n, speakers, properties, false);
        }

        this.state = {
            speakers: speakers,
            template: this.props.template && (this.props.template as any).template
                && getBlocTemplateFor(this.props.event.data, (this.props.template as any).template),
            templateProperties: this.props.template && (this.props.template as any).templateProperties
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.speakersData !== this.props.data.speakersData) {
            let speakers = this.props.data.speakersData.filter((s) => !!s);

            if (speakers) {
                const properties = (this.props.template as any)?.templateProperties || {};
                if (!properties.sort) {
                    properties.sort = { propName: 'lastname' };
                }

                speakers = SortAndFilterItems(
                    this.props.user?.detail as any,
                    this.props.i18n,
                    speakers,
                    properties,
                    false
                );
            }

            this.setState({ speakers });
        }
    }

    render() {
        let canClick = false;
        const templateprovider = this.props.itemtemplateprovider;
        let childtemplate = this.state.template;
        let templateProperties = this.state.templateProperties;
        let row; const
            classNames = ["sessionitemspeakers"];
        if (templateprovider && !childtemplate) {
            childtemplate = templateprovider.getTemplate("speakertemplate");
            if (!templateProperties) {
                templateProperties = templateprovider.getTemplateProperties();
            }
        }

        canClick = templateProperties && templateProperties.canClickSpeakers;

        if (this.state.speakers) {
            classNames.push("speakers-" + this.state.speakers.length);

            if (this.state.speakers.length === 1) {
                classNames.push("single");
            } else if (this.state.speakers.length > 1) {
                classNames.push("multi");
            }

            row = this.state.speakers.map((speaker) => {
                const s = speaker;
                // console.log("rendering speaker", s, childtemplate);
                if (s !== null && s !== undefined) {
                    if (childtemplate && !this.props.roomId) {
                        let link = null;
                        if (canClick) {
                            link = this.props.urlservice.pageUrl(`speaker/${s.id}`);
                        }
                        return <ItemTemplate
                            key={s.id}
                            page={this.props.page}
                            user={this.props.user}
                            urlservice={this.props.urlservice}
                            datacontext={this.props.datacontext}
                            template={childtemplate}
                            linkto={link}
                            className="sessionspeakeritem"
                            data={s}
                            theme={this.props.theme}
                        />;
                    } if (this.props.template && (this.props.template as any).config
                        && (this.props.template as any).config.template && !this.props.roomId) {
                        let link2 = null;
                        if (canClick) {
                            link2 = this.props.urlservice.pageUrl(`speaker/${s.id}`);
                        }
                        return <ItemTemplate
                            page={this.props.page}
                            user={this.props.user}
                            urlservice={this.props.urlservice}
                            datacontext={this.props.datacontext}
                            linkto={link2}
                            template={(this.props.template as any).config.template}
                            data={s}
                            theme={this.props.theme}
                        />;
                    }
                    if (!this.props.roomId) {
                        let company;
                        if (s.company) {
                            company = <span className="company"> ({s.company})</span>;
                        }
                        return <span key={s.id} className="speaker">
                            <span className="firstname">{s.firstname}</span>
                            <span className="lastname">{s.lastname}</span>{company}</span>;
                    }
                    if (this.props.roomId) {
                        return <span key={s.id} className="speaker room-infos">
                            <span className="firstname">{s.firstname}</span>
                            <span className="lastname">{s.lastname}</span>
                            {s.jobTitle || s.company ? " - " : ""}
                            <span className="jobtitle">{s.jobTitle}</span>
                            <span className="company">{s.company}</span>
                        </span>;
                    }
                }
                return null;
            });
        }

        return <div className={classNames.join(" ")}>
            {row}
        </div>;
    }
}
