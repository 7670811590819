import * as React from 'react';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import * as moment from 'moment';
import * as momenttimezone from 'moment-timezone';
import { Entities } from '@inwink/entities/entities';
import { AppTextLabel } from '@@services/i18nservice';
import {
    BaseCalendarGeneratorComponent, IBaseCalendarGeneratorProps, getLocationDetails, downloadCalendar
} from '@@components/calendargenerator';

declare const ics;

export interface ICalendarSessionsGeneratorProps extends IBaseCalendarGeneratorProps {
    items: any,
    allSessions?: boolean;
    label?: VisualTheme.IAppLabel;
    itemAction?: boolean;
    i18nHelper?: Entities.i18nHelper;
}

@withI18nHelper()
export class CalendarSessionsGenerator extends BaseCalendarGeneratorComponent<ICalendarSessionsGeneratorProps, any> {
    constructor(props: ICalendarSessionsGeneratorProps) {
        super(props);
        this.state = {
            ready: false
        };
    }

    getElement(element, i18n) {
        // todo utiliser un translate
        let final;
        if (element) {
            if (Object.keys(element).length) {
                if (element[i18n.currentLanguageCode]) {
                    final = element[i18n.currentLanguageCode];
                } else {
                    final = element[i18n.defaultlng];
                }
            } else {
                final = element;
            }
        }
        return final;
    }

    generate = (arg) => {
        arg.preventDefault();
        arg.stopPropagation();
        const calendar = ics();
        const item = this.props.items;
        const formatDate = (date) => {
            return this.props.event.detail.timeZone
                ? momenttimezone.tz(date, this.props.event.detail.timeZone) : moment(date);
        };
        // const timeslot = this.props.items.timeslots[0];
        const timezone = this.props.event.detail.timeZone;
        const title = item.title ? this.getElement(item.title, this.props.i18n) : '';
        let description = item.description ? this.getElement(item.description, this.props.i18n) : '';
        // format \n to avoid issue when importing ics
        if (description) {
            description = description.split("\r").join("\\r");
            description = description.split("\n").join("\\n").trim();
        }
        const timeslot = item.timeslots[0];
        // const roomName = timeslot && timeslot.room && timeslot.room.name ? timeslot.room.name : '';
        const locationDetails = getLocationDetails(this.props);
        const startDate = timeslot && timeslot.startDate ? timeslot.startDate : '';
        const endDate = timeslot && timeslot.endDate ? timeslot.endDate : '';
        const sd = startDate ? formatDate(startDate) : null;
        const ed = endDate ? formatDate(endDate) : null;
        const computedUrl = this.props.event?.detail?.computedUrl + '/session/' + item.id;
        calendar.addEvent(title, description, locationDetails, sd, ed, timezone, momenttimezone, null, '15', computedUrl);
        const blob = calendar.getBlob();
        downloadCalendar(blob, 'session-' + title);
    };

    render() {
        if (!this.state.ready) {
            return <div className="empty" />;
        }
        let title;
        const i18n = this.props.i18nHelper;
        if (this.props.label && Object.keys(this.props.label).length) {
            title = i18n.translateBag(this.props.label, this.props.i18n, this.props.i18n.defaultlng);
        } else {
            title = <AppTextLabel i18n="addonesessiontocalendar.button.title" />;
        }
        // Pour afficher dans l'item session, mettre "allowexporttocalendar" à true dans le Json du gabarit.
        if (this.props.itemAction) {
            return <button
                type="button"
                onClick={this.generate}
                key="sessiontocalendar"
                className="action lightbtn favaction clickable bloc-lightborder inwink-export"
            />;
        }
        return <button
            type="button"
            className="bloc-lightborder lightbtn"
            onClick={this.generate}
        >
            <i className="inwink-export" />
            {title}
        </button>;
    }
}
