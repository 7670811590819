import { registerItemTemplatePart } from '@inwink/itemtemplate/templatecatalog';

import { SessionItemAction } from './itemtemplate/sessionitemaction';
import { SessionMessageItemAction } from './itemtemplate/sessionmessageitemaction';
import { SessionItemSpeakers } from './itemtemplate/sessionitemspeakers';
import { SessionItemEventThemes } from './itemtemplate/sessionitemeventthemes';
import { SessionItemExhibitors } from './itemtemplate/sessionitemexhibitors';
import { SessionActions } from './itemtemplate/entityactions.session';

export function registerSessionTemplateParts() {
    registerItemTemplatePart("entityactions.session", SessionActions);
    registerItemTemplatePart("sessionitemaction", SessionItemAction);
    registerItemTemplatePart("sessionitemspeakers", SessionItemSpeakers);
    registerItemTemplatePart("sessionitemeventthemes", SessionItemEventThemes);
    registerItemTemplatePart("sessionitemexhibitors", SessionItemExhibitors);
    registerItemTemplatePart("sessionmessageitemaction", SessionMessageItemAction);
}
