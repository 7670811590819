import * as React from 'react';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { getBlocTemplate } from '@@services/itemshelpers';
import { getMoment } from '@@services/i18nservice';
import { IDynamicBlocProps, DynamicBloc } from '@@components/dynamicpage/common';
import { BlocTitle } from "@@components/dynamicpage/common.title";
import { ItemTemplate } from '@@components/templates/itemtemplate';
import type { States } from '@@services/services';

import './bloc.teasersessionsbydays.less';

export interface IDayItem {
    date: moment.Moment;
    label: string;
    style: any;
}

export interface IBlocTeaserSessionsByDaysState {
    itemtemplate: VisualTheme.IItemTemplate;
}

function getDays(startDate: moment.Moment, endDate: moment.Moment) {
    const days = [startDate];
    let current = startDate.clone();
    while (current < endDate) {
        current = current.clone().add(1, 'days');
        days.push(current);
    }
    return days;
}

export function blocTeaserSessionsByDaysData(page: States.ICurrentPageState, statedata: IBlocTeaserSessionsByDaysState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bloc: VisualTheme.IBlocContentTemplateBase, state: States.IAppState, dispatch: (action) => void) {
    const blocdata = statedata;
    const data = state.event.data;
    blocdata.itemtemplate = getBlocTemplate(data, bloc);
}

export class BlocTeaserSessionsByDays extends React.PureComponent<IDynamicBlocProps<IBlocTeaserSessionsByDaysState>, any> {
    render() {
        const eventStartDate = moment(this.props.event.detail.startDate).startOf('day');
        const eventEndDate = moment(this.props.event.detail.endDate).startOf('day');
        const days = getDays(eventStartDate, eventEndDate);
        const dayItems = days.map((d) => {
            let label; let
                style;
            const key = d.format("YYYY-MM-DD");
            if (this.props.template.properties.days && this.props.template.properties.days[key]) {
                const data = this.props.template.properties.days[key];
                label = data.text;
                if (typeof label !== "string" && label) {
                    label = data.text[this.props.i18n.currentLanguageCode];
                }
                style = data.style;
            }
            if (!label) {
                label = getMoment(this.props.i18n, d).format("LL");
            }
            return {
                date: d,
                label: label,
                style: style
            };
        });

        const daysElts = dayItems.map((dd, idx) => {
            const d = dd;
            if (typeof d.date === "string") {
                d.date = moment(d.date);
            }
            if (!this.props.blocState) return null;
            return <Link
                key={"day" + idx}
                style={d.style}
                className="clickable sessionday"
                to={this.props.urlservice.pageUrl(`sessions?timeslotday=${d.date.format("YYYY-MM-DD")}`)}
            >
                <ItemTemplate
                    data={d}
                    user={this.props.user.currentUser}
                    i18n={this.props.i18n}
                    template={this.props.blocState.itemtemplate}
                    page={this.props.page}
                    theme={this.props.theme}
                    datacontext={this.props.datacontext}
                    urlservice={this.props.urlservice}
                />
            </Link>;
        });
        return <DynamicBloc {...this.props}>
            <BlocTitle {...this.props} className="bloc-header" />
            <div className="bloc-content teasersessionsbydays">
                {daysElts}
            </div>
        </DynamicBloc>;
    }
}
