import * as React from 'react';
import { Loader } from '@inwink/loader';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import type {States} from '@@services/services';
import {wrapReduxStore, IInwinkStore} from '@@store/index';

import './sessionmessageitemaction.less';

function sessionMessageActions() {
    return import('../data.sessionmessages');
}
interface ISessionMessageItemActionProps extends ItemTemplateProps {
    store?: IInwinkStore;
}

class SessionMessageItemActionComponent extends React.Component<ISessionMessageItemActionProps, any> {
    constructor(props: ItemTemplateProps) {
        super(props);
        this.state = {
            isLoading: false
        };

        this.like = this.like.bind(this);
        this.unlike = this.unlike.bind(this);
    }

    like(arg: React.MouseEvent<any>) {
        arg.preventDefault();
        arg.stopPropagation();
        sessionMessageActions().then((mod) => {
            mod.sessionMessageActions.like(arg.target as HTMLElement, this.props.i18nHelper, this.props.data)(
                this.props.store.dispatch,
                this.props.store.getState
            );
            this.setState({});
        });
    }

    unlike(arg: React.MouseEvent<any>) {
        arg.preventDefault();
        arg.stopPropagation();
        sessionMessageActions().then((mod) => {
            mod.sessionMessageActions.unlike(arg.target as HTMLElement, this.props.i18nHelper, this.props.data)(
                this.props.store.dispatch,
                this.props.store.getState
            );
            this.setState({});
        });
    }

    likeBtn() {
        if ((global as any).disableAuthenticatedFeatures
            || (this.props.template && this.props.template.properties && this.props.template.properties.hideBtn)) {
            return undefined;
        }

        if (this.state.isLoading) {
            return <div className="asyncloader"><Loader message=" " /></div>;
        }

        const userstate = this.props.datacontext.userstate as States.IAppUserState;

        if (userstate && userstate.currentUser
            && userstate.currentUser.data && userstate.currentUser.data.favoriteEventMessages) {
            const userfavorite = userstate.currentUser.data.favoriteSessionMessages
                .data.find((e) => e.entityId === this.props.data.id);
            if (userfavorite) {
                return <button
                    key="likeunlike"
                    className="action lightbtn favaction clickable checked inwink-heart"
                    onClick={this.unlike}
                />;
            }
        }
        return <button key="likeunlike" onClick={this.like} className="action lightbtn favaction clickable inwink-heart" />;
    }

    likes() {
        if (this.props.template && this.props.template.properties && this.props.template.properties.hideLikes) {
            return undefined;
        }

        return <span className="likes">{this.props.data.likes}</span>;
    }

    render() {
        const likeBtn = this.likeBtn();
        const likes = this.likes();

        return <div className="sessionmessageitemactions itemactions">
            {likes}
            {likeBtn}
        </div>;
    }
}

export const SessionMessageItemAction = wrapReduxStore(SessionMessageItemActionComponent);