import * as React from 'react';
import type { Entities } from '@inwink/entities/entities';
import { DynLabel } from '@inwink/i18n';
import type { States } from '@@services/services';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import { ItemTemplate } from '@@components/templates/itemtemplate';
import { withItemTemplateProvider, IItemTemplateProvider } from '@@components/templates/itemtemplateprovider';

export interface ISessionItemEventThemesProps extends ItemTemplateProps {
    itemtemplateprovider?: IItemTemplateProvider;
    data: {
        eventThemes?: {
            id: string
        }[],
        eventThemesData?: Entities.IEventTheme[]
    };
    theme: any;
}

@withItemTemplateProvider()
export class SessionItemEventThemes extends React.PureComponent<ISessionItemEventThemesProps, any> {
    render() {
        let canClick = false;
        let row; let childtemplate; const
            classNames = ["sessionitemeventthemes"];
        const templateprovider = this.props.itemtemplateprovider;
        if (templateprovider) {
            childtemplate = templateprovider.getTemplate("eventthemetemplate");
            const templateProperties = templateprovider.getTemplateProperties();
            if (templateProperties) canClick = templateProperties.canClickEventThemes;
        }

        if (this.props.data.eventThemes && this.props.data.eventThemes.length > 0) {
            if (!this.props.datacontext || !this.props.datacontext.eventstate) return;

            const eventstate = this.props.datacontext.eventstate as States.IEventState;
            const eventthemes = this.props.data.eventThemes.map((s) => {
                return eventstate.data.eventthemes.data.find((e) => e.id === s.id);
            }).filter((s) => !!s);

            classNames.push("eventthemes-" + eventthemes.length);
            if (eventthemes.length === 1) {
                classNames.push("single");
            } else if (eventthemes.length > 1) {
                classNames.push("multi");
            }

            if (childtemplate) {
                row = eventthemes.map((s) => {
                    let link = null;
                    if (canClick) {
                        link = this.props.urlservice.pageUrl(`eventtheme/${s.id}`);
                    }

                    return <ItemTemplate
                        key={s.id}
                        page={this.props.page}
                        user={this.props.user}
                        urlservice={this.props.urlservice}
                        linkto={link}
                        datacontext={this.props.datacontext}
                        template={childtemplate}
                        className={"sessioneventthemeitem item-" + s.id}
                        data={s}
                        theme={this.props.theme}
                    />;
                });
            } else {
                row = eventthemes.map((s) => {
                    if (s !== null && s !== undefined) {
                        const bkgColor = { backgroundColor: s.visualConfig?.color };
                        const textColor = { color: s.visualConfig?.textColor };
                        return <span 
                            key={s.id} 
                            className={"sessioneventthemeitem keywordbubble bloc-accentbg item-" + s.id}
                            style={bkgColor}>
                            <DynLabel 
                                component="span" 
                                i18n={s.title}
                                style={textColor}/>
                        </span>;
                    }
                    return null;
                });
            }
        } else if (this.props.data.eventThemesData && this.props.data.eventThemesData.length > 0) {
            row = this.props.data.eventThemesData.map((s) => {
                if (s !== null && s !== undefined) {
                    if (this.props.template) {
                        return <ItemTemplate
                            key={s.id}
                            page={this.props.page}
                            user={this.props.user}
                            urlservice={this.props.urlservice}
                            datacontext={this.props.datacontext}
                            template={(this.props.template as any).config.template}
                            className={"sessioneventthemeitem item-" + s.id}
                            data={s}
                            theme={this.props.theme}
                        />;
                    }

                    return <span key={s.id} className={"sessioneventthemeitem item-" + s.id}><DynLabel i18n={s.title} /></span>;
                }
                return null;
            });
        }

        return <div className={classNames.join(" ")}>
            {row}
        </div>;
    }
}
