import * as React from 'react';
import type { Entities } from '@inwink/entities/entities';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import { ItemTemplate } from '@@components/templates/itemtemplate';
import type { States } from '@@services/services';
import { withItemTemplateProvider, IItemTemplateProvider } from '@@components/templates/itemtemplateprovider';

import './sessionitemspeakers.less';

export interface ISessionItemExhibitorsProps extends ItemTemplateProps {
    itemtemplateprovider?: IItemTemplateProvider;
    data: {
        exhibitors?: {
            id: string
        }[],
        exhibitorsData?: Entities.IExhibitor[]
    };
    theme: any;
}

@withItemTemplateProvider()
export class SessionItemExhibitors extends React.PureComponent<ISessionItemExhibitorsProps, any> {
    render() {
        if (this.props.datacontext && this.props.data && this.props.data.exhibitors && this.props.data.exhibitors.length > 0) {
            const templateprovider = this.props.itemtemplateprovider;
            let canClick = false;
            const eventstate = this.props.datacontext.eventstate as States.IEventState;
            const exhibitors = this.props.data.exhibitors.map((s) => {
                return eventstate.data.exhibitors.data.find((e) => e.id === s.id);
            }).filter((s) => !!s);

            if (templateprovider) {
                const childtemplate = templateprovider.getTemplate("exhibitortemplate");
                const templateProperties = templateprovider.getTemplateProperties();
                if (templateProperties) canClick = templateProperties.canClickExhibitors;

                if (childtemplate) {
                    return <div className="sessionexhibitors">
                        {
                            exhibitors.map((e: Entities.IExhibitor) => {
                                let link = null;
                                if (canClick) {
                                    link = this.props.urlservice.pageUrl(`partners/${e.id}`);
                                }

                                return <ItemTemplate
                                    key={e.id}
                                    page={this.props.page}
                                    user={this.props.user}
                                    urlservice={this.props.urlservice}
                                    datacontext={this.props.datacontext}
                                    linkto={link}
                                    template={childtemplate}
                                    className="sessionexhibitoritem"
                                    data={e}
                                    theme={this.props.theme}
                                />;
                            })
                        }
                    </div>;
                }
                return null;
            }
            return null;
        }
        return null;
    }
}
